@font-face {
  font-family: "HM Slussen Expanded";
  src: url("./fonts/HM-Slussen-Condensed-Regular.otf");
}

@font-face {
  font-family: "HM Slussen Condensed";
  src: url("./fonts/HM-Slussen-Condensed-Regular.otf");
}
@font-face {
  font-family: "HM Slussen";
  src: url("./fonts/HM-Slussen-Regular.otf");
}
@font-face {
  font-family: "HM Group Ampersand";
  src: url("./fonts/HMGroupAmpersand-Regular.otf");
}
@font-face {
  font-family: "HM Sans";
  src: url("./fonts/HMSansRegular.otf");
}

.main-container {
  background: linear-gradient(113.14deg, #fdedf3 17.83%, #fcefe3 91.54%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  width: 100%;
}
.background {
  background-image: url("../public/background.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.unauthorized-bg {
  background-image: url("../public/unauthorized-bg.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.lheading {
  color: #111;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "HM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 62.5% */
}
.unauthorized-heading {
  font-family: "HM Slussen Condensed";
  font-size: 30px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #333333;
}
.pcontent {
  color: #111;
  font-family: "HM Slussen Condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 1.4px;
}

.app-logo {
  background: url("../public/assets/svgs/myntra-sprite.svg") no-repeat -40px -359px;

  width: 178px;
  height: 66px;
  display: inline-flex;
}
.bg-login {
  border-radius: 25px !important;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(12px);
  /* width: 47%; */
}
.align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 38.5vh; */
}
.unauthorized-height {
  /* height: 64vh !important; */
}
.card-padding {
  padding: 6.5% 5% !important;
}
.btn-login-button {
  border-radius: 4px;
  background-color: #222 !important;
  border: #222 !important;
  display: flex;
  width: 200px;
  height: 44px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
